import { graphql } from 'gatsby';
import React from 'react';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import { PostCard } from '../components/PostCard';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  inner,
  outer,
  PostFeed,
  SiteDescription,
  SiteHeader,
  SiteHeaderContent,
  SiteMain,
  SiteTitle,
  SiteNavMain,
  SiteArchiveHeader,
  ResponsiveHeaderBackground,
  SiteHeaderBackground,
} from '../styles/shared';
import { PageContext } from './post';
import { Helmet } from 'react-helmet';
import config from '../website-config';

interface TagTemplateProps {
  location: Location;
  pageContext: {
    tag: string;
  };
  data: {
    strapiCategory: {
      id: string
      name: string
      slug: string
      articles: Array<PageContext>
    }
    allStrapiArticle: {
      nodes: Array<PageContext>
    }
  };
}

const Tags = ({ pageContext, data, location }: TagTemplateProps) => {
  // const tag = pageContext.tag ? pageContext.tag : '';
  const tag = data.strapiCategory.name
  // const { edges, totalCount } = data.allMarkdownRemark;

  const totalCount = data.allStrapiArticle.nodes.length
  // const tagData = data.allTagYaml.edges.find(
  //   n => n.node.id.toLowerCase() === tag.toLowerCase(),
  // );

  return (
    <IndexLayout>
      <Helmet>
        <html lang={config.lang} />
        <title>{tag} - {config.title}</title>
        {/* <meta name="description" content={tagData?.node ? tagData.node.description : ''} /> */}
        <meta name="description" content={`notizie relative a ${data.strapiCategory.name}`} />
        <meta property="og:site_name" content={config.title} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${tag} - ${config.title}`} />
        <meta property="og:url" content={config.siteUrl + location.pathname} />
        {config.facebook && <meta property="article:publisher" content={config.facebook} />}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${tag} - ${config.title}`} />
        <meta name="twitter:url" content={config.siteUrl + location.pathname} />
        {/* {config.twitter && (
          <meta
            name="twitter:site"
            content={`@${config.twitter.split('https://twitter.com/')[1]}`}
          />
        )} */}
      </Helmet>
      <Wrapper>
        <header
          className="site-archive-header"
          css={[SiteHeader, SiteArchiveHeader]}
        >
          <div css={[outer, SiteNavMain]}>
            <div css={inner}>
              <SiteNav isHome={false} />
            </div>
          </div>
          <ResponsiveHeaderBackground
            css={[outer, SiteHeaderBackground]}
            // backgroundImage={tagData?.node?.image?.childImageSharp?.fluid?.src}
            className="site-header-background"
          >
            <SiteHeaderContent css={inner} className="site-header-content">
              <SiteTitle className="site-title">{tag}</SiteTitle>
              <SiteDescription className="site-description">
                {/* {tagData?.node.description ? (
                  tagData.node.description
                ) : ( */}
                  <>
                    A collection of {totalCount > 1 && `${totalCount} posts`}
                    {totalCount === 1 && '1 post'}
                    {totalCount === 0 && 'No posts'}
                  </>
                {/* )} */}
              </SiteDescription>
            </SiteHeaderContent>
          </ResponsiveHeaderBackground>
        </header>
        <main id="site-main" css={[SiteMain, outer]}>
          <div css={inner}>
            <div css={[PostFeed]}>
              {data.allStrapiArticle.nodes.map(( node ) => (
                <PostCard key={node.slug} post={node} />
              ))}
            </div>
          </div>
        </main>
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export default Tags;

export const pageQuery = graphql`
  query($tag: Int) {

    strapiCategory(strapiId: {eq: $tag}) {
      id
      name
      slug
      articles {
        id
        title
      }
    }

    allStrapiArticle(
      sort: { fields: published_at, order: DESC }
      filter: { tags: {elemMatch: {id: {eq: $tag}}}}
    ) {
      nodes {
        title
        description
        content
        slug
        published_at
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
              original {
                src
                height
                width
              }
            }
          }
        }
        authors {
          id
          name
          picture {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        tags {
          name
        }
      }
    }
  }
`;
